import "@components/commerce/checkout/payment-step/PaymentStepForm.css";
import RootComponentWrapper from "@components/RootWrapper";
import { DefaultSeo } from "@components/seo/DefaultSeo";
import { reCaptchaIntegrationEnabled } from "@config/site/site-config";
import type {
    IMicrocopySet,
    IModuleSizeGuideModal,
    ISiteSettings,
} from "@contentful-api/types/contentful";
import type { HandlerType } from "@datadog/browser-logs";
import { datadogLogs } from "@datadog/browser-logs";
import { datadogRum } from "@datadog/browser-rum";
import type { AppPropsWithLayout } from "@interfaces/PageInterfaces";
import { ABTestingScript } from "@lib/analytics/ABTesting";
import { AnalyticsProvider } from "@lib/analytics/AnalyticsProvider";
import { CookieConsentScript } from "@lib/analytics/CookieConsentScript";
import { gtmScriptString } from "@lib/analytics/gtm";
import { Logger, LogTag, ServiceType } from "@lib/monitoring/logger";
import { reportMetrics } from "@lib/monitoring/report-metrics";
import { isIOSDevice } from "@lib/utils/commonUtility";
import { hasWindow } from "@lib/utils/hasWindow";
import { initializeKount } from "@lib/utils/kount/session";
import { deserialize } from "@ungap/structured-clone";
import { SessionProvider } from "next-auth/react";
import dynamic from "next/dynamic";
import Head from "next/head";
import { useRouter } from "next/router";
import Script from "next/script";
import "../fonts.css";
import { ZustandProvider } from "../store";

const ContentfulLivePreviewProvider = dynamic(() =>
    import("@contentful/live-preview/react").then((mod) => mod.ContentfulLivePreviewProvider)
);

const ThemeProvider = dynamic(() => import("@ui/ThemeProvider").then((mod) => mod.ThemeProvider));
const MicrocopyProvider = dynamic(() =>
    import("@lib/contentful/microcopy/MicrocopyContext").then((mod) => mod.MicrocopyProvider)
);
const GlobalModalProvider = dynamic(() =>
    import("@components/GlobalModalProvider").then((mod) => mod.GlobalModalProvider)
);
const UrqlProviderWrapper = dynamic(() => import("@components/UrqlProviderWrapper"));

const PreviewMode = dynamic(() => import("@components/cms/preview/PreviewMode"));

export const reportWebVitals = reportMetrics;

if (hasWindow) {
    initializeKount();
    datadogLogs.init({
        clientToken: "pubf0ffdea92d462ccd5a5c74700027c1cf",
        site: "datadoghq.eu",
        forwardErrorsToLogs: false,
        forwardConsoleLogs: [],
        forwardReports: [],
        sessionSampleRate: 100,
        service: "ghc-frontend",
    });
    const dataDogHandlers = ["http"];

    datadogLogs.logger.setHandler(dataDogHandlers as HandlerType[]);
    datadogRum.init({
        applicationId: "7c269a0e-abfb-460a-9f11-d50ef10f0dda",
        clientToken: "pubf42ce21688e95d6e43138edfd8de72a6",
        site: "datadoghq.eu",
        service: "rum",
        sessionSampleRate: 10,
        sessionReplaySampleRate: 0,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: "mask-user-input",
        allowedTracingUrls: [/^https?:\/\/[^/]+\/api/, /^https?:\/\/[^/]+\/_next/],
    });
}

const EccoApp = (props: AppPropsWithLayout) => {
    const { Component, pageProps } = props;
    const preview = props["__N_PREVIEW"];

    const { locale, isPreview } = useRouter();
    const {
        pageType,
        serializedSiteSettings,
        serializedMicrocopySets,
        serializedSizeGuideModal,
        serializedPageMicrocopySet,
        serializedGlobalMicrocopySet,
    } = pageProps || {};

    const getLayout = Component.getLayout;

    if (pageProps.datadogCustomMetric) {
        Logger.warn(ServiceType.WEB, pageProps.datadogCustomMetric.message, {
            tag: LogTag.PERFORMANCE,
            ...pageProps.datadogCustomMetric.metric,
        });
    }

    // Serialized empty object so the page doesn't crash if the data is not available
    const serializationFallback = [[2, []]]; // = serialize({});

    const microcopySets: { [key: string]: IMicrocopySet } = Object.entries(
        serializedMicrocopySets ?? {}
    ).reduce((acc, [key, set]) => {
        acc[key] = deserialize(set || serializationFallback);
        return acc;
    }, {});

    const globalMicrocopySet: IMicrocopySet = deserialize(
        serializedGlobalMicrocopySet || serializationFallback
    );
    const pageMicrocopySet: IMicrocopySet = deserialize(
        serializedPageMicrocopySet || serializationFallback
    );
    const siteSettings: ISiteSettings = deserialize(
        serializedSiteSettings || serializationFallback
    );
    const sizeGuideModal: IModuleSizeGuideModal = deserialize(
        serializedSizeGuideModal || serializationFallback
    );

    const seoMetadata = siteSettings?.fields?.seoMetadata;
    const authorizationModules = {
        offerToLogIn: siteSettings?.fields?.offerToLogIn,
        offerToCreateAnAccount: siteSettings?.fields?.offerToCreateAnAccount,
        policiesAgreementTerms: siteSettings?.fields?.policiesAgreementTerms,
        authorizationInvitation: siteSettings?.fields?.authorizationInvitation,
        forgotYourPassword: siteSettings?.fields?.forgotYourPassword,
    };

    const contactFormModules = {
        contactFormAgreement: siteSettings?.fields?.contactFormAgreement,
        reCaptchaAgreement: siteSettings?.fields?.reCaptchaAgreement,
    };

    const shippingDetailsModal = {
        shippingDetailsModal: siteSettings?.fields?.shippingDetailsModal,
    };
    const profileLinkCollection = siteSettings?.fields?.profileLinkCollection;

    const liveChatModal = {
        liveChatAgreement: siteSettings?.fields?.liveChatAgreement,
    };

    const microcopyProviderContent = (
        <MicrocopyProvider
            value={{
                global: globalMicrocopySet,
                page: pageMicrocopySet,
                ...microcopySets,
            }}
        >
            {seoMetadata && <DefaultSeo seoModule={seoMetadata} pathName={pageProps.pathName} />}
            <PreviewMode preview={preview} />
            <GlobalModalProvider
                authorizationModules={authorizationModules}
                linkCollection={profileLinkCollection}
                sizeGuideModal={sizeGuideModal}
                contactFormModules={contactFormModules}
                shippingDetailsModal={shippingDetailsModal}
                liveChatModal={liveChatModal}
            >
                <RootComponentWrapper>
                    {getLayout ? (
                        getLayout({
                            page: <Component {...pageProps} />,
                            siteSettings,
                            pageProps,
                        })
                    ) : (
                        <Component {...pageProps} />
                    )}
                </RootComponentWrapper>
            </GlobalModalProvider>
        </MicrocopyProvider>
    );

    return (
        <>
            <CookieConsentScript locale={locale} />
            <Script
                id="gtmScript"
                strategy="afterInteractive"
                dangerouslySetInnerHTML={{ __html: gtmScriptString }}
            />
            {reCaptchaIntegrationEnabled && (
                <Script
                    id="recaptchaScript"
                    src={`https://www.google.com/recaptcha/enterprise.js?render=${process.env.NEXT_PUBLIC_RECAPTCHA_TOKEN}`}
                    strategy="afterInteractive"
                />
            )}
            <ABTestingScript />
            <Head>
                <meta
                    name="viewport"
                    content={`width=device-width, initial-scale=1, maximum-scale=${isIOSDevice() ? "1" : "5"}`}
                />
                <meta name="referrer" content="same-origin" />
                <style>{`.grecaptcha-badge { visibility: hidden; z-index: 5000 !important; position:  static !important }`}</style>
            </Head>
            <SessionProvider session={pageProps.session}>
                <ZustandProvider initialState={pageProps.initialZustandState}>
                    <UrqlProviderWrapper>
                        <ThemeProvider>
                            <AnalyticsProvider pageType={pageType}>
                                {isPreview ? (
                                    <ContentfulLivePreviewProvider
                                        //ContentfulLivePreviewProvider doesn't support multilocale, and we are previewing only EN, so we need to hardcode it
                                        locale={"en"}
                                        enableLiveUpdates={preview}
                                        enableInspectorMode={preview}
                                    >
                                        {microcopyProviderContent}
                                    </ContentfulLivePreviewProvider>
                                ) : (
                                    microcopyProviderContent
                                )}
                            </AnalyticsProvider>
                        </ThemeProvider>
                    </UrqlProviderWrapper>
                </ZustandProvider>
            </SessionProvider>
        </>
    );
};

export default EccoApp;
